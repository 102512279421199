import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common'; import { ProgressStepsComponent } from '@app/shared/ui/progress-steps/progress-steps.component';
import { InputLabelComponent } from '@app/shared/ui/input-label/input-label.component';
import { InputComponent } from '@app/shared/ui/input/input.component';
import { ButtonDirective } from '@app/shared/directives/button.directive';
import { Router } from '@angular/router';
import { individualRoutesConfig } from '@create-account/individual/individual-routes.config';
import { BaseSelectDirective } from '@app/shared/directives/base-select.directive';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { InputFileComponent } from '@app/shared/ui/input-file/input-file.component';
import { KycService } from '@app/core/services/client/kyc.service';
import { AuthSelectors } from '@app/auth/store/auth.selectors';
import { Store } from '@ngxs/store';
import { MEANS_OF_ID } from '@app/core/constants/app.constant';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { MeansOfIdKyc } from '@app/core/models/client/kyc';
import { SlideInRightModalComponent } from '@app/shared/ui/slide-in-right-modal/slide-in-right-modal.component';
import { CreateAccountKinComponent } from '../create-account-kin/create-account-kin.component';
import { AccountService } from '@app/core/services/client/account.service';
import { AuthActions } from '@app/auth/store/auth.actions';

@Component({
  selector: 'app-create-account-identification',
  standalone: true,
  imports: [
    CommonModule,
    SlideInRightModalComponent,
    ProgressStepsComponent,
    InputLabelComponent,
    InputComponent,
    ButtonDirective,
    BaseSelectDirective,
    ReactiveFormsModule,
    FormsModule,
    InputFileComponent,
    CreateAccountKinComponent
  ],
  templateUrl: './create-account-identification.component.html',
  styleUrl: './create-account-identification.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateAccountIdentificationComponent implements OnDestroy, OnInit {
  INDIVIDUAL_ROUTES = individualRoutesConfig.identification;

  documentInfo: {
    id: string;
    key: string;
    inputLabel: string;
    isFileUploaded: boolean;
    meansOfIdFile: File | null;
    base64Image: string | ArrayBuffer | null;
  } = {
      id: 'meansOfId',
      key: 'meansOfId',
      inputLabel: 'Means Of Identification',
      isFileUploaded: false,
      meansOfIdFile: null,
      base64Image: null,
    };



  store = inject(Store);
  router = inject(Router);
  change = inject(ChangeDetectorRef);
  fb = inject(FormBuilder);
  kycService = inject(KycService);
  accService = inject(AccountService);
  toast = inject(ToastrService);

  ID: string = ' or Scan ID';
  subs: Subscription[] = [];
  isLoading: boolean = false;
  uploadInProgress: boolean = false;
  meansOfIdTypes: string[] = []
  isKinModalOpen: boolean = false;
  countries: string[] = [];

  accountId = this.store.selectSnapshot(AuthSelectors.getaccountID);




  identificationForm = this.fb.nonNullable.group({
    type: ['', Validators.required],
    number: ['', Validators.required],
    nationality: ['', Validators.required],
    // uploadedFile :[null as unknown as File, Validators.required]
  });

  ngOnInit(): void {
    this.getEnums();
  }

  getEnums() {
    const sub = this.store.select(AuthSelectors.getAllEnumData).subscribe(allEnumData => {
      if (allEnumData?.Countries && allEnumData.Countries.length > 0) {
        this.countries = allEnumData.Countries;
        this.meansOfIdTypes = allEnumData.MeansOfIdentification
        this.change.detectChanges()
      } else {
        this.store.dispatch(new AuthActions.EnumsList());
      }
    });
    this.subs.push(sub);
  }

  getUploadedFile(fileList: FileList | null, documentType: string): void {
    if (fileList) {
      this.documentInfo.isFileUploaded = true;
      this.documentInfo.meansOfIdFile = fileList[0];
      this.uploadDocument();
    } else {
      this.documentInfo.isFileUploaded = false;
      this.documentInfo.meansOfIdFile = null;
    }
  }

  uploadDocument() {
    this.uploadInProgress = true;
    const formData = new FormData();
    const documentFile = this.documentInfo.meansOfIdFile;

    if (documentFile) {
      formData.append('file', documentFile as File);
      formData.append('document_type', 'means_of_id');
      if (this.accountId) {
        formData.append('account_id', this.accountId);
      } else {
        console.error('Account ID is null or undefined');
      }
    }

    const sub = this.kycService.uploadKycDocument(formData).subscribe({
      next: (response: HttpEvent<any>) => {
        this.uploadInProgress = false;
        this.change.detectChanges();

        if (response.type === HttpEventType.Response) {
          this.toast.success(`Successfully uploaded Document`);
        }
      },
      error: (response) => {
        this.uploadInProgress = false;
        this.change.detectChanges();

        this.toast.error('Failed to upload Document');
      },
    });

    this.subs.push(sub);
  }

  submitForm() {

    const updatePayload: MeansOfIdKyc = {
      account_id: this.accountId,
      ...this.identificationForm.getRawValue(),
    };
    this.isLoading = true;
    const sub = this.kycService
      .addMeansOfId(updatePayload)
      .subscribe({
        next: (response) => {
          this.isLoading = false;
          this.change.detectChanges();

          this.toast.success(response.message);
          this.router.navigateByUrl('/dashboard/individual/kin')
        },
        error: (resp) => {
          this.isLoading = false;
          this.change.detectChanges();

          this.toast.error(
            resp?.error?.message ||
            'Failed to add means of identification details'
          );
        },
      });
    this.subs.push(sub);
  }
  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  closeModal(): void {
    history.back()
  }
  skip(): void {
    this.router.navigateByUrl('/dashboard/individual/kin')
  }
}



