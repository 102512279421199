<app-slide-in-right-modal
  [slideOpen]="true"
  (action)="closeModal()"
>
  <div class="main__container flex flex-col">
    <div class="flex items-center justify-center mb-5">
      <span class="text-xl font-bold text-mango-grey text-center flex-1">Personal Details</span>
      <a class="font-extrabold text-mango text-sm cursor-pointer" (click)="skip()">
        Skip
      </a>
    </div>

    <div class="page__container flex flex-col pb-8">
      <div class="steps__container mb-[1.19rem] xl:mb-5">
        <app-progress-steps
          [totalSteps]="6"
          [currentStep]="1"
          iconImageSrc="/assets/images/svgs/user--outline.svg"
        >
        </app-progress-steps>
      </div>

      <p class="cta__text text-sm text-mango-gray74 xl:text-center">
        Hi there, tell us a little about yourself!
      </p>

      <form
        class="flex flex-col gap-5 px-0 pt-5"
        [formGroup]="personalDetailsForm"
        (ngSubmit)="updateProfile()"
      >
        <!-- Gender and Marital Status -->
        <div class="form-rows">
          <!-- Gender -->
          <div>
            <app-input-label labelFor="gender" labelClass="label--bottom-margin"
              >Gender</app-input-label
            >
            <select name="gender" required base-select formControlName="gender">
              <option value="" disabled selected>Select gender</option>
              <option *ngFor="let gender of genderList" [value]="gender.value">
                {{ gender.label }}
              </option>
            </select>
          </div>

          <div>
            <app-input-label
              labelFor="marital status"
              labelClass="label--bottom-margin"
              >Marital Status</app-input-label
            >
            <select
              name="marital-status"
              required
              base-select
              formControlName="marital_status"
            >
              <option value="" disabled selected>Select status</option>
              <option *ngFor="let status of maritalStatus" [value]="status.value">
                {{ status.label }}
              </option>
            </select>
          </div>
        </div>

        <!-- Phone Number and DOB -->
        <div class="form-rows">
          <div>
            <app-input-label
              labelFor="phone-number"
              labelClass="label--bottom-margin"
              >Phone Number</app-input-label
            >
            <div class="relative rounded-lg shadow-sm">
              <div class="absolute inset-y-0 left-0 flex items-center">
                <label for="country" class="sr-only">Phone Number</label>
                <select
                name="phone-number"
                autocomplete="tel"
                class="h-full rounded-lg border-0 bg-transparent py-0 pr-7 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-mango sm:text-sm border-inherit rounded-r-none border-r"
              >
                <option>NG</option>
                <option>US</option>
                <option>CA</option>
                <option>EU</option>
              </select>
              </div>
              <input
                type="text"
                name="phone-number"
                formControlName="phone_number_2"
                class="phone-input block w-full rounded-lg border-0 pl-16 text-base text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-mango sm:leading-6"
                placeholder="9001234567"
                maxlength="11"
                inputmode="numeric"
              />
            </div>
          </div>

          <div>
            <app-input-label
              labelFor="date of birth"
              labelClass="label--bottom-margin"
              >Date of Birth</app-input-label
            >
              <input
                placeholder="DD/MM/YYYY"
                
                formControlName="dob"
                class="block w-full rounded-lg border-0 pl-2 text-base text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-mango sm:leading-6"
                type="date"
                
              >
           
          </div>
        </div>

        <!-- State of Origin and Bank Verification Number -->
        <div class="form-rows">
          <div>
            <app-input-label
              labelFor="state"
              labelClass="label--bottom-margin"
              >State</app-input-label
            >
            <select
              name="state"
              required
              base-select
              formControlName="state"
            >
              <option value="" disabled selected>Select State</option>
              @for (state of nigerianStates; track $index) {
                <option [value]="state.state_code">{{ state.name }}</option>
              }
            </select>
          </div>

          <div>
            <app-input-label
              labelFor="bank-verification-number"
              labelClass="label--bottom-margin"
              >Bank Verification Number</app-input-label
            >
            <app-input
              placeholder="Enter Bank Verification Number"
              inputClass="input--padding"
              formControlName="bvn"
            ></app-input>
          </div>
        </div>

        <!-- Country and Address -->
        <div class="form-rows">
          <div>
            <app-input-label
              labelFor="bank-verification-number"
              labelClass="label--bottom-margin"
              >Address</app-input-label
            >
            <app-input
              placeholder="Enter current address"
              inputClass="input--padding"
              formControlName="address"
            ></app-input>
          </div>
          <div>
            <app-input-label
              labelFor="scountry"
              labelClass="label--bottom-margin"
              >Country</app-input-label
            >
            <select
              name="country"
              required
              base-select
              formControlName="country"
            >
              <option value="" disabled selected>Select Country</option>
              @for (country of countries; track $index) {
                <option [value]="country.value">{{ country.value }}</option>
              }
            </select>
          </div>
        </div>


        <!-- Annual Income and Source of fund -->
        <div class="form-rows">
          <div>
            <app-input-label
              labelFor="what-is-your-annual-income"
              labelClass="label--bottom-margin"
              >Annual Income</app-input-label
            >
            <select
              name="what-is-your-annual-income"
              required
              base-select
              formControlName="annual_income"
            >
              <option value="" disabled selected>Select Annual Income</option>
              <option *ngFor="let range of annualIncomeRange" [value]="range.value">
                {{ range.label }}
              </option>
            </select>
          </div>

          <div>
            <app-input-label
              labelFor="source-of-fund"
              labelClass="label--bottom-margin"
              >Source of fund</app-input-label
            >
            <select
              name="source-of-fund"
              required
              base-select
              formControlName="source_of_fund"
            >
              <option value="" disabled selected>Select source of Fund</option>
              <option *ngFor="let source of fundSources" [value]="source.value">
                {{ source.label }}
              </option>
            </select>
          </div>
        </div>
        <!-- place of birth -->
        <div class="form-rows">
          <div>
            <app-input-label
              labelFor="place_of_birth"
              labelClass="label--bottom-margin"
              >Place of Birth</app-input-label
            >
            <select
              name="place_of_birth"
              required
              base-select
              formControlName="place_of_birth"
            >
              <option value="" disabled selected>Select Place of birth</option>
              @for (country of countries; track $index) {
                <option [value]="country.value">{{ country.value }}</option>
              }
            </select>
          </div>

          <!-- Political Exposure -->
          <div>
            <app-input-label
              labelFor="political-exposure"
              labelClass="label--bottom-margin"
            >
              Political Exposure
            </app-input-label>
            <div class="flex gap-4">
              <label class="flex items-center gap-2">
                <input
                  type="radio"
                  [value]="true"
                  formControlName="political_exposure"
                  class="radio-input h-5 w-5 appearance-none border-2 border-mango-lightGray text-mango focus:ring-mango"
                />
                Yes
              </label>
              <label class="flex items-center gap-2">
                <input
                  type="radio"
                  [value]="false"
                  formControlName="political_exposure"
                  class="radio-input h-5 w-5 appearance-none border-2 border-mango-lightGray text-mango focus:ring-mango"
                />
                No
              </label>
            </div>
          </div>
        </div>

        <button
          
          type="submit"
          class="submit-button cursor-pointer"
          mango-button
          [disabled]="personalDetailsForm.invalid || (isLoading$ | async)"
        >
        {{ (isLoading$ | async) ? 'Submitting...' : 'Continue' }}
        </button>
      </form>
    </div>
  </div>
</app-slide-in-right-modal>

<!-- <div *ngIf="isIdentityModalOpen">
  <app-create-account-identification (action)="isIdentityModalOpen = false;"></app-create-account-identification>
</div> -->