import { ChangeDetectionStrategy, Component, inject, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressStepsComponent } from '@app/shared/ui/progress-steps/progress-steps.component';
import { InputLabelComponent } from '@app/shared/ui/input-label/input-label.component';
import { InputComponent } from '@app/shared/ui/input/input.component';
import { ButtonDirective } from '@app/shared/directives/button.directive';
import { Router } from '@angular/router';
import { BaseSelectDirective } from '@app/shared/directives/base-select.directive';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { KIN_RELATIONSHIPS, NIGERIAN_TITLES } from '@app/core/constants/app.constant';
import { AuthSelectors } from '@app/auth/store/auth.selectors';
import { Store } from '@ngxs/store';
import { KycService } from '@app/core/services/client/kyc.service';
import { ToastrService } from 'ngx-toastr';
import { LoadingService } from '@app/core/services/loading.service';
import { NextOfKinKyc } from '@app/core/models/client/kyc';
import { SlideInRightModalComponent } from '@app/shared/ui/slide-in-right-modal/slide-in-right-modal.component';
import { CreateAccountBankComponent } from '../../corperate/create-account-bank/create-account-bank.component';


@Component({
  selector: 'app-create-account-kin',
  standalone: true,
  imports: [
    CommonModule,
    SlideInRightModalComponent,
    ProgressStepsComponent,
    InputLabelComponent,
    InputComponent,
    ButtonDirective,
    BaseSelectDirective,
    ReactiveFormsModule,
    FormsModule,
    CreateAccountBankComponent
  ],
  templateUrl: './create-account-kin.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateAccountKinComponent {
  // INDIVIDUAL_ROUTES = individualRoutesConfig.nextOfKin;

  fb = inject(FormBuilder);
  change = inject(ChangeDetectorRef);
  store = inject(Store);
  kycService = inject(KycService);
  router = inject(Router);
  private _toast = inject(ToastrService);
  private loadingService = inject(LoadingService);


  isLoading$!: Observable<boolean>;
  profileID = this.store.selectSnapshot(AuthSelectors.getProfileID);
  accountID = this.store.selectSnapshot(AuthSelectors.getActiveAccountID);
  subs: Subscription[] = [];
  isBankModalOpen: boolean = false;

  kinRelationships = KIN_RELATIONSHIPS;
  titles = Object.entries(NIGERIAN_TITLES).map(([key, value]) => ({
    key,
    value,
  }));

  nextOfKinForm = this.fb.nonNullable.group({
    title: ['', Validators.required],
    gender: ['', Validators.required],
    first_name: ['', Validators.required],
    last_name: ['', Validators.required],
    email: ['', Validators.required],
    phone_number: ['', Validators.required],
    relationship_with_nok: ['', Validators.required],
    dob: [new Date(), Validators.required],
  });

  ngOnInit(): void { }
  addNextOfKin() {
    this.loadingService.setLoading('create-account-kin', true);
    // Prepare the body with form values, profileId and accountId
    const updatePayload: NextOfKinKyc = {
      // profile_id: this.profileID,
      account_id: this.accountID,
      ...this.nextOfKinForm.getRawValue(),
    };
    const sub = this.kycService
      .addNextOfKin(updatePayload)
      .subscribe({
        next: () => {
          this.loadingService.setLoading('create-account-kin', false);
          this.router.navigateByUrl('/dashboard/individual/bank')

          this._toast.success('Next of kin added successfully');
          this.change.detectChanges();
        },
        error: (resp) => {
          this.loadingService.setLoading('create-account-kin', false);
          this.change.detectChanges();
          this._toast.error('Failed to add Next of Kin');
        },
      });

    this.subs.push(sub);
  } constructor() {
    this.isLoading$ = this.loadingService.getLoadingObservable(
      'create-account-kin'
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  closeModal(): void {
    history.back()
  }
  skip(): void {
    this.router.navigateByUrl('/dashboard/individual/bank')
  }
}
