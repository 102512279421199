<app-slide-in-right-modal [slideOpen]="true" (action)="closeModal()">

  <div class="main__container flex flex-col gap-[1.88rem]">
    <div class="page__container w-full">
      <div class="flex items-center justify-center mb-5">
        <span class="text-xl font-bold text-mango-grey text-center">Select Account Type</span>
      </div>

      <div class="flex flex-col gap-5 xl:col-start-2 xl:col-end-4">
        <p class="cta__text !mb-0 text-sm font-normal leading-snug text-mango-gray74">
          Choose your Account! Tell us which account type fits your needs
        </p>

        <button (click)="createIndividualAccount()"
          class="account-type__container flex items-center justify-between bg-white hover:bg-mango-floralWhite focus-visible:outline-mango">
          <div class="flex flex-col gap-5">
            <div class="svg__container flex items-center justify-center rounded-full bg-mango-papayaWhip">
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
                <path
                  d="M13.0003 12.5003C15.3024 12.5003 17.167 10.6357 17.167 8.33366C17.167 6.03158 15.3024 4.16699 13.0003 4.16699C10.6982 4.16699 8.83366 6.03158 8.83366 8.33366C8.83366 10.6357 10.6982 12.5003 13.0003 12.5003ZM13.0003 14.5837C10.2191 14.5837 4.66699 15.9795 4.66699 18.7503V20.8337H21.3337V18.7503C21.3337 15.9795 15.7816 14.5837 13.0003 14.5837Z"
                  fill="#3F4951" />
              </svg>
            </div>
            <span class="text-sm font-normal text-mango-black17">Individual Account</span>
          </div>

          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
              <path d="M9.5 19L15.5 12L9.5 5" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </div>
        </button>

        <button (click)="createCorporateAccount()"
          class="account-type__container flex items-center justify-between bg-white hover:bg-mango-floralWhite focus-visible:outline-mango">
          <div class="flex flex-col gap-5">
            <div class="svg__container flex items-center justify-center rounded-full bg-mango-papayaWhip">
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
                <g clip-path="url(#clip0_900_37852)">
                  <path
                    d="M13 7.8125C13.6181 7.8125 14.2223 7.62922 14.7362 7.28584C15.2501 6.94246 15.6506 6.45441 15.8871 5.88339C16.1236 5.31237 16.1855 4.68403 16.065 4.07784C15.9444 3.47165 15.6467 2.91483 15.2097 2.47779C14.7727 2.04075 14.2158 1.74313 13.6097 1.62255C13.0035 1.50197 12.3751 1.56385 11.8041 1.80038C11.2331 2.0369 10.745 2.43744 10.4017 2.95134C10.0583 3.46525 9.875 4.06943 9.875 4.6875C9.875 5.5163 10.2042 6.31116 10.7903 6.89721C11.3763 7.48326 12.1712 7.8125 13 7.8125Z"
                    fill="#3F4951" />
                  <path
                    d="M21.3333 17.1878H14.0417V14.0628H17.6875C17.9638 14.0628 18.2287 13.9531 18.4241 13.7577C18.6194 13.5624 18.7292 13.2974 18.7292 13.0212V10.9378C17.1049 9.62453 15.0885 8.8913 13 8.85449C10.9115 8.8913 8.89514 9.62453 7.27083 10.9378V13.0212C7.27083 13.2974 7.38058 13.5624 7.57593 13.7577C7.77128 13.9531 8.03623 14.0628 8.3125 14.0628H11.9583V17.1878H4.66667C4.3904 17.1878 4.12545 17.2976 3.9301 17.4929C3.73475 17.6883 3.625 17.9532 3.625 18.2295V22.3962C3.625 22.6724 3.73475 22.9374 3.9301 23.1327C4.12545 23.3281 4.3904 23.4378 4.66667 23.4378C4.94293 23.4378 5.20789 23.3281 5.40324 23.1327C5.59859 22.9374 5.70833 22.6724 5.70833 22.3962V19.2712H11.9583V22.3962C11.9583 22.6724 12.0681 22.9374 12.2634 23.1327C12.4588 23.3281 12.7237 23.4378 13 23.4378C13.2763 23.4378 13.5412 23.3281 13.7366 23.1327C13.9319 22.9374 14.0417 22.6724 14.0417 22.3962V19.2712H20.2917V22.3962C20.2917 22.6724 20.4014 22.9374 20.5968 23.1327C20.7921 23.3281 21.0571 23.4378 21.3333 23.4378C21.6096 23.4378 21.8746 23.3281 22.0699 23.1327C22.2653 22.9374 22.375 22.6724 22.375 22.3962V18.2295C22.375 17.9532 22.2653 17.6883 22.0699 17.4929C21.8746 17.2976 21.6096 17.1878 21.3333 17.1878Z"
                    fill="#3F4951" />
                </g>
                <defs>
                  <clipPath id="clip0_900_37852">
                    <rect width="25" height="25" fill="white" transform="translate(0.5)" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <span class="text-sm font-normal text-mango-black17">Corporate Account</span>
          </div>

          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
              <path d="M9.5 19L15.5 12L9.5 5" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </div>
        </button>
      </div>
    </div>
  </div>
</app-slide-in-right-modal>


<!-- <ng-container>
  @if (isIndividualPersonalOpen) {
    <app-personal (action)="isIndividualPersonalOpen = false;"></app-personal>
  }

  @if (isCoperateCompanyDetailsOpen) {
    <app-create-account-company (action)="isCoperateCompanyDetailsOpen = false;"></app-create-account-company>
  }
</ng-container> -->