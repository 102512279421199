<app-slide-in-right-modal
  [slideOpen]="true"
  (action)="closeModal()"
>
  <div class="company__wrapper flex flex-col gap-[1.88rem] xl:gap-5">
    <div class="flex items-center justify-between mb-5">
      <span class="text-xl font-bold text-mango-grey text-center flex-1">Director's Details</span>
      <a class="font-extrabold text-mango text-sm cursor-pointer" (click)="skip()">
        Skip
      </a>
    </div>

      <div
        class="page-content__wrapper flex flex-col gap-[1.88rem] xl:gap-5 xl:col-start-2 xl:col-end-4"
      >
        <div class="grid grid-cols-[1fr_2fr_1fr] mb-[1.19rem] xl:mb-5">
          <app-progress-steps
            class="col-start-2"
            [totalSteps]="7"
            [currentStep]="3"
            iconImageSrc="/assets/images/svgs/user-rounded--outline.svg"
          >
          </app-progress-steps>
        </div>

        <div class="form__wrapper flex flex-col gap-5">
          <p class="text-sm text-mango-gray74 cta__text">
            Please enter the following input fields with the correct
            information.
          </p>

          <form class="flex flex-col gap-5" [formGroup]="directorDetailsForm" (ngSubmit)="addDirectorDetails()">
            <!-- First name -->
            <div>
              <app-input-label
                labelFor="firstName"
                labelClass="label--bottom-margin"
                >First name</app-input-label
              >
              <app-input
                placeholder="Enter First Name"
                inputClass="input--padding"
                formControlName="first_name"
              ></app-input>
            </div>
            <!-- Last name -->
            <div>
              <app-input-label
                labelFor="lastName"
                labelClass="label--bottom-margin"
                >Last name</app-input-label
              >
              <app-input
                placeholder="Enter Last Name"
                inputClass="input--padding"
                formControlName="last_name"
              ></app-input>
            </div>

            <!-- Email -->
            <div>
              <app-input-label
                labelFor="email"
                labelClass="label--bottom-margin"
                >Email</app-input-label
              >
              <app-input
                placeholder="Enter email"
                inputClass="input--padding"
                formControlName="email"
                inputmode="email"
              ></app-input>
            </div>

            <!-- Phone number -->
            <div>
              <app-input-label
                labelFor="phone-number"
                labelClass="label--bottom-margin"
                >Phone number</app-input-label
              >
              <div class="relative rounded-lg shadow-sm">
                <div class="absolute inset-y-0 left-0 flex items-center">
                  <label for="phoneNumber" class="sr-only">Phone number</label>
                  <select
                    name="phoneNumber"
                    autocomplete="tel"
                    class="h-full rounded-lg border-0 bg-transparent py-0 pr-7 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-mango sm:text-sm border-inherit rounded-r-none border-r"
                  >
                <option>NG</option>
                <option>US</option>
                <option>CA</option>
                <option>EU</option>
                  </select>
                </div>
                <input
                  type="text"
                  name="phoneNumber"
                  formControlName="phone_number"
                  class="block w-full rounded-lg border-0 pl-16 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-mango text-base sm:leading-6 phone-input"
                  placeholder="5676536789"
                  maxlength="11"
                  inputmode="numeric"
                />
              </div>
            </div>

            <!-- Bank Verification Number -->
            <div>
              <app-input-label
                labelFor="bankVerificationNumber"
                labelClass="label--bottom-margin"
                >Bank Verification Number</app-input-label
              >
              <app-input
                placeholder="Enter Bank Verification Number "
                inputClass="input--padding"
                formControlName="bvn"
              ></app-input>
            </div>

            <button
              mango-button
              type="submit"
              class="cursor-pointer"
              [disabled]="directorDetailsForm.invalid || (isLoading$ | async)"
              [loading]="directorDetailsForm.invalid || (isLoading$ | async) ?? false"
              
            >
            {{ (isLoading$ | async) ? 'Saving...' : 'Save' }}
            </button>
          </form>
        </div>
      </div>
  </div>
</app-slide-in-right-modal>

<!-- <div *ngIf="isConfirmDirectoryModalOpen">
  <app-create-account-confirm-director (action)="isConfirmDirectoryModalOpen = false;"></app-create-account-confirm-director>
</div> -->