<app-slide-in-right-modal
  [slideOpen]="true"
  (action)="closeModal()"
>
  <div class="company__wrapper flex flex-col gap-[1.88rem] xl:gap-5">
    <div class="flex items-center justify-between mb-5">
      <span class="text-xl font-bold text-mango-grey text-center flex-1">Bank Details</span>
      <a class="font-extrabold text-mango text-sm cursor-pointer" (click)="skip()">
        Skip
      </a>
    </div>
    
      <div
        class="page-content__wrapper flex flex-col gap-[1.88rem] xl:gap-5 xl:col-start-2 xl:col-end-4"
      >
        <div class="grid grid-cols-[1fr_2fr_1fr] mb-[1.19rem] xl:mb-5">
          <app-progress-steps
            class="col-start-2"
            [totalSteps]="7"
            [currentStep]="2"
            iconImageSrc="/assets/images/svgs/building-two--outline.svg"
          >
          </app-progress-steps>
        </div>

        <div class="form__wrapper flex flex-col gap-5">
          <p class="text-sm text-mango-gray74 cta__text">
            Time to link your account! Enter your bank details securely. We only
            use this info for transactions and never store sensitive data.
          </p>

          <form class="flex flex-col gap-5" [formGroup]="bankDetailsForm" (ngSubmit)="addBankDetails()">
            <!-- Currency -->
            <div>
              <app-input-label
                labelFor="currency"
                labelClass="label--bottom-margin"
                >Currency</app-input-label
              >
                  <select name="currency" required base-select formControlName="currency">
                    <option value="" disabled selected>Select currency</option>
                    <option
                      *ngFor="let currency of currencyList"
                      [value]="currency.value"
                    >
                      {{ currency.label }}
                    </option>
                  </select>
            </div>


            <!-- Account number -->
            <div>
              <app-input-label
                labelFor="accountNumber"
                labelClass="label--bottom-margin"
                >Account number</app-input-label
              >
              <app-input
                placeholder="Enter Account number"
                inputClass="input--padding"
                formControlName="bank_account_number"
                [disabled]="
                  (!bankName.value && (isFetchingAcctNo$ | async) === true)
                "
              ></app-input>

                <!-- Validation error message -->
                <div *ngIf="bankDetailsForm.get('bank_account_number')?.errors?.['minlength'] || bankDetailsForm.get('bank_account_number')?.errors?.['maxlength']">
                  <small class="mt-2 text-sm text-red-600">Account number must be exactly 10 digits.</small>
                </div>
            </div>

            <!-- Bank name -->
            <div>
              <app-input-label labelFor="bank-name" labelClass="label--bottom-margin"
                >Bank name</app-input-label
              >
              <select
                name="bank-name"
                required
                base-select
                formControlName="bank_code"
                #bankName
              >
                <option value="" disabled selected>
                  {{
                    banks.length === 0
                      ? '...Fetching the list of banks'
                      : 'Select Bank'
                  }}
                </option>
                @for (bank of banks; track $index) {
                  <option value="{{ bank.bankCode }}">{{ bank.bankName }}</option>
                }
              </select>
            </div>
            
            <!-- Account name -->
            <div>
            <app-input-label
              labelFor="registrationNumber"
              labelClass="label--bottom-margin"
              >Account name</app-input-label
            >
            <app-input
              [placeholder]="
                (isFetchingAcctNo$ | async)
                  ? '...Fetching Account Name'
                  : 'Enter Account Name'
              "
              inputClass="input--padding"
              formControlName="bank_account_name"
              [readonly]="true"
            ></app-input>
          </div>

            <!-- Bank Sort Code -->
            <div>
              <app-input-label
                labelFor="bankSortCode"
                labelClass="label--bottom-margin"
                >Bank Sort Code</app-input-label
              >
              <app-input
                placeholder="Enter Sort Code"
                inputClass="input--padding"
                formControlName="bank_sort_code"
              ></app-input>
            </div>



            <!-- Bank Branch -->
            <div>
              <app-input-label
                labelFor="bankSortCode"
                labelClass="label--bottom-margin"
                >Bank Branch</app-input-label
              >
              <app-input
                placeholder="Enter Bank Branch"
                inputClass="input--padding"
                formControlName="bank_branch"
              ></app-input>
             
            </div>

            <button
              mango-button
              type="submit"
              class="cursor-pointer"
              
              [disabled]="bankDetailsForm.invalid || (isLoading$ | async)"
              [loading]="bankDetailsForm.invalid || (isLoading$ | async) ?? false"
            >
            {{ (isLoading$ | async) ? 'Submitting...' : 'Continue' }}
            </button>
          </form>
        </div>
      </div>
  </div>
</app-slide-in-right-modal>

<!-- <div *ngIf="isDirectorDetailsModalOpen">
  <app-create-account-director-details (action)="isDirectorDetailsModalOpen = false;"></app-create-account-director-details>
</div> -->
