import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  EventEmitter,
  Output,
  OnInit
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressStepsComponent } from '@app/shared/ui/progress-steps/progress-steps.component';
import { InputLabelComponent } from '@app/shared/ui/input-label/input-label.component';
import { InputComponent } from '@app/shared/ui/input/input.component';
import { ButtonDirective } from '@app/shared/directives/button.directive';
import {
  Router,
} from '@angular/router';
import { BaseSelectDirective } from '@app/shared/directives/base-select.directive';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { LoadingService } from '@app/core/services/loading.service';
import { FUND_SOURCE, GENDER, MARITAL_STATUS } from '@app/core/constants/app.constant';
import { NIGERIAN_STATES } from '@app/core/data/nigerian-states';
import { Store } from '@ngxs/store';
import { AuthSelectors } from '@app/auth/store/auth.selectors';
import { KycService } from '@app/core/services/client/kyc.service';
import { ProfileKyc } from '@app/core/models/client/kyc';
import { ToastrService } from 'ngx-toastr';
import { SlideInRightModalComponent } from '@app/shared/ui/slide-in-right-modal/slide-in-right-modal.component';
import { CreateAccountIdentificationComponent } from '../create-account-identification/create-account-identification.component';
import { COUNTRY_ALPHA_CODES } from '@app/core/data/countries';
import { AccountService } from '@app/core/services/client/account.service';

@Component({
  selector: 'app-personal',
  standalone: true,
  imports: [
    CommonModule,
    ProgressStepsComponent,
    SlideInRightModalComponent,
    InputLabelComponent,
    InputComponent,
    ButtonDirective,
    BaseSelectDirective,
    ReactiveFormsModule,
    FormsModule,
    CreateAccountIdentificationComponent
  ],
  templateUrl: './personal.component.html',
  styleUrl: './personal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonalComponent implements OnInit {
  // INDIVIDUAL_ROUTES = individualRoutesConfig.personalDetails;
  @Output() action = new EventEmitter<void>();

  fb = inject(FormBuilder);
  change = inject(ChangeDetectorRef);
  router = inject(Router);
  store = inject(Store);
  kycService = inject(KycService)
  private _toast = inject(ToastrService);
  private loadingService = inject(LoadingService);
  accountService = inject(AccountService)
  isLoading$!: Observable<boolean>;
  profileID = this.store.selectSnapshot(AuthSelectors.getProfileID);
  accountID = this.store.selectSnapshot(AuthSelectors.getActiveAccountID);
  subs: Subscription[] = [];
  isIdentityModalOpen: boolean = false;
  isLoading: boolean = false;
  genderList = GENDER;
  maritalStatus = MARITAL_STATUS;
  nigerianStates = NIGERIAN_STATES;
  annualIncomeRange: any[] = [];
  fundSources: any[] = [];
  countries = COUNTRY_ALPHA_CODES;


  personalDetailsForm = this.fb.nonNullable.group({
    gender: ['', Validators.required],
    marital_status: ['', Validators.required],
    phone_number_2: ['', Validators.required],
    dob: [new Date(), Validators.required],
    state: ['', Validators.required],
    address: ['', Validators.required],
    country: ['', Validators.required],
    bvn: ['', Validators.required],
    annual_income: ['', Validators.required],
    source_of_fund: ['', Validators.required],
    place_of_birth: ['', Validators.required],
    political_exposure: [null, Validators.required],
  });

  ngOnInit(): void {
    this.getAllEnums();
  }


  updateProfile() {

    this.loadingService.setLoading('create-account-personal', true);
    // Prepare the body with form values, profileId and accountId
    const updatePayload: ProfileKyc = {
      profile_id: this.profileID,
      account_id: this.accountID,
      ...this.personalDetailsForm.getRawValue(),
    };

    const sub = this.kycService
      .updateProfile(updatePayload)
      .subscribe({
        next: (response) => {
          this.loadingService.setLoading('create-account-personal', false);
          this.router.navigateByUrl('/dashboard/individual/identification')

          this._toast.success('Profile updated successfully');
          this.change.detectChanges();
        },
        error: (resp) => {
          this.loadingService.setLoading('create-account-personal', false);
          this.change.detectChanges();
          this._toast.error('Failed to update profile');
        },
      });

    this.subs.push(sub);

  } constructor() {
    this.isLoading$ = this.loadingService.getLoadingObservable(
      'create-account-personal'
    );
  }

  getAllEnums() {
    this.isLoading = true;
    const sub = this.accountService
      .getAllEnums()
      .subscribe({
        next: (response) => {
          this.isLoading = false;
          this.annualIncomeRange = this.convertApiToDropdown(response?.data?.AnnualIncome);
          this.fundSources = this.convertApiToDropdown(response?.data?.SourceOfFund);

          this.change.detectChanges();
        },
        error: (resp) => {
          this.isLoading = false;
          this.change.detectChanges();
        },
      });
    this.subs.push(sub);
  }

  convertApiToDropdown(apiData: string[]): { value: string; label: string }[] {
    return apiData.map((item) => {
      return { value: item, label: item };
    });
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
  closeModal(): void {
    history.back()
  }
  skip(): void {
    this.router.navigateByUrl('/dashboard/individual/identification')
  }
}
