<app-slide-in-right-modal
  [slideOpen]="true"
  (action)="closeModal()"
>
  <div class="main__container">
    <div class="flex items-center justify-center mb-5">
      <span class="text-xl font-bold text-mango-grey text-center flex-1">Next of Kin</span>
      <a class="font-extrabold text-mango text-sm cursor-pointer" (click)="skip()">
        Skip
      </a>
    </div>

    <div class="grid__container xl:grid xl:grid-cols-4 pb-8">
      <div
        class="steps col-start-2 col-end-4 mb-[1.19rem] mt-[1.88rem] xl:my-5"
      >
        <app-progress-steps
          [totalSteps]="6"
          [currentStep]="3"
          iconImageSrc="/assets/images/svgs/user--outline.svg"
        >
        </app-progress-steps>
      </div>

      <p class="text-sm text-mango-gray74 col-start-1 col-end-5 cta__text">
        Because we care about your well-being, let's set up your emergency
        contacts! Your Next of Kin will be there for you when you need it most.
      </p>

      <form
        [formGroup]="nextOfKinForm"
        (ngSubmit)="addNextOfKin()"
        
        class="form__container flex flex-col gap-5 xl:gap-[1.88rem] xl:auto-cols-fr col-start-1 col-end-5 my-5 xl:my-[1.88rem]"
      >
        <div class="form-row flex flex-col gap-5 xl:grid xl:grid-cols-2 xl:gap-5">
       
          <!-- Title -->
          <div>
            <app-input-label labelFor="title" labelClass="label--bottom-margin"
              >Title</app-input-label
            >
            <select name="title" required base-select formControlName="title">
              <option value="" disabled selected>Title</option>
              <option *ngFor="let title of titles" [value]="title.key">
                {{ title.value }}
              </option>
            </select>
          </div>

          <!-- Gender -->
          <div>
            <app-input-label labelFor="gender" labelClass="label--bottom-margin"
              >Gender</app-input-label
            >
            <select name="gender" required base-select formControlName="gender">
              <option value="" disabled selected>Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </div>
        </div>

        <div class="form-row flex flex-col gap-5 xl:grid xl:grid-cols-2 xl:gap-5">
          <!-- First Name -->
          <div>
            <app-input-label
              labelFor="first_name"
              labelClass="label--bottom-margin"
              >First Name</app-input-label
            >

            <app-input
              placeholder="Enter First Name"
              [inputClass]="'input--padding'"
              formControlName="first_name"
            ></app-input>
          </div>

          <!-- Last Name -->
          <div>
            <app-input-label
              labelFor="last_name"
              labelClass="label--bottom-margin"
              >Last Name</app-input-label
            >

            <app-input
              placeholder="Enter Last Name"
              [inputClass]="'input--padding'"
              formControlName="last_name"
            ></app-input>
          </div>
        </div>

        <div
          class="form-row flex flex-col gap-5 xl:grid xl:grid-cols-2 xl:gap-5"
        >
          <!-- Email Address -->
          <div>
            <app-input-label
              labelFor="email"
              labelClass="label--bottom-margin"
              >Email Address</app-input-label
            >

            <app-input
              placeholder="Enter Email Address"
              [inputClass]="'input--padding'"
              formControlName="email"
            ></app-input>
          </div>

          <!-- Phone number -->
          <div>
            <app-input-label
              labelFor="phone_number"
              labelClass="label--bottom-margin"
              >Phone Number</app-input-label
            >
            <div class="relative rounded-md shadow-sm">
              <div class="absolute inset-y-0 left-0 flex items-center">
                <label for="country" class="sr-only">Phone Number</label>
                <select
                  name="phone-number"
                  autocomplete="tel"
                  class="h-full rounded-md border-0 bg-transparent py-0 pr-7 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-mango sm:text-sm border-inherit rounded-r-none border-r"
                >
                <option value="NG" selected>NG</option>
                  <option value="US">US</option>
                  <option value="CA">CA</option>
                  <option value="EU">EU</option>
                </select>
              </div>
              <input
                type="text"
                name="phone-number"
                formControlName="phone_number"
                class="block w-full rounded-md border-0 pl-16 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-mango text-base sm:leading-6 phone-input"
                placeholder="5676536789"
                maxlength="11"
              />
            </div>
          </div>
        </div>

        <div
          class="form-row flex flex-col gap-5 xl:grid xl:grid-cols-2 xl:gap-5"
        >
          <!-- Relationship with next of kin -->
          <div>
            <app-input-label
              labelFor="relationship_with_nok"
              labelClass="label--bottom-margin"
              >State relationship with next of kin</app-input-label
            >

            <select
              name="kinRelationship"
              required
              base-select
              formControlName="relationship_with_nok"
            >
              <option value="" disabled selected>Relationship</option>
              <option *ngFor="let kin of kinRelationships" [value]="kin.value">
                {{ kin.label }}
              </option>
            </select>
          </div>

            <div>
              <app-input-label
                labelFor="date of birth"
                labelClass="label--bottom-margin"
                >Date of Birth</app-input-label
              >
                <input
                  placeholder="DD/MM/YYYY"
                  
                  formControlName="dob"
                  class="block w-full rounded-lg border-0 pl-2 text-base text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-mango sm:leading-6"
                  type="date"
                  
                >
          </div>
        </div>
        
      </form>   
      <button
        type="submit"
        (click)="addNextOfKin()"
        class="submit-button cursor-pointer ml-auto"
        mango-button
        [disabled]="nextOfKinForm.invalid || (isLoading$ | async)"
      >
        {{ (isLoading$ | async) ? 'Submitting...' : 'Continue' }}
    </button> 
    </div>
  </div>
</app-slide-in-right-modal>

<!-- <div *ngIf="isBankModalOpen">
  <app-create-account-bank></app-create-account-bank>
</div> -->

