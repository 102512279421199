<app-slide-in-right-modal
  [slideOpen]="true"
  (action)="closeModal()"
>
  <div class="main__container flex flex-col" >
    <div class="flex items-center justify-between mb-5">
      <span class="text-xl font-bold text-mango-grey text-center flex-1">Company Details</span>
      <a class="font-extrabold text-mango text-sm cursor-pointer" (click)="skip()">
        Skip
      </a>
    </div>

      <div class="page__container flex flex-col pb-8">
        <div class="grid grid-cols-[1fr_2fr_1fr] mb-[1.19rem] xl:mb-5">
          <app-progress-steps
            class="col-start-2"
            [totalSteps]="7"
            [currentStep]="1"
            iconImageSrc="/assets/images/svgs/building-two--outline.svg"
          >
          </app-progress-steps>
        </div>

        <div class="form__wrapper flex flex-col gap-5">
          <p class="text-sm text-mango-gray74 cta__text">
            Let's get to know your company! Provide essential details for a
            personalized experience
          </p>

          <form class="flex flex-col gap-5" [formGroup]="companyDetailsForm" (ngSubmit)="updateProfile()">
            <!-- Company name -->
            <div>
              <app-input-label
                labelFor="companyName"
                labelClass="label--bottom-margin"
                >Company name</app-input-label
              >
              <app-input
                placeholder="Enter name"
                inputClass="input--padding"
                formControlName="name"
              ></app-input>
              @if(companyDetailsForm.get('name')?.touched && companyDetailsForm.get('name')?.invalid) {
                <span class="text-sm text-red-600">Company name is required</span>
              }
            </div>

            <!-- Registration number -->
            <div>
              <app-input-label
                labelFor="registrationNumber"
                labelClass="label--bottom-margin"
                >Registration number</app-input-label
              >
              <app-input
                placeholder="Enter registration number"
                inputClass="input--padding"
                formControlName="registration_number"
              ></app-input>
              @if(companyDetailsForm.get('registration_number')?.touched && companyDetailsForm.get('registration_number')?.invalid) {
                <span class="text-sm text-red-600">Registration number is required</span>
              }
            </div>

            <!-- TIN -->
            <div>
              <app-input-label labelFor="tin" labelClass="label--bottom-margin"
                >TIN</app-input-label
              >
              <app-input
                placeholder="Enter TIN"
                inputClass="input--padding"
                formControlName="tin"
              ></app-input>
              @if(companyDetailsForm.get('tin')?.touched && companyDetailsForm.get('tin')?.invalid) {
                <span class="text-sm text-red-600">TIN is required</span>
              }
            </div>

            <!-- Date of registration -->
            <div>
              <app-input-label
                labelFor="date of registration"
                labelClass="label--bottom-margin"
                >Date of registration</app-input-label
              >


              <input
                placeholder="DD/MM/YYYY"
                
                formControlName="registered_date"
                class="block w-full rounded-lg border-0 pl-2 text-base text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-mango sm:leading-6"
                type="date"
                
              >
              @if(companyDetailsForm.get('registered_date')?.touched && companyDetailsForm.get('registered_date')?.invalid) {
                <span class="text-sm text-red-600">Date of registration is required</span>
              }
            </div>

            <button
              type="submit"
              class="submit-button cursor-pointer"
              mango-button
              [disabled]="companyDetailsForm.invalid || (isLoading$ | async)"
              [loading]="companyDetailsForm.invalid || (isLoading$ | async) ?? false"
            >
            {{ (isLoading$ | async) ? 'Submitting...' : 'Continue' }}
            </button>
          </form>
        </div>
      </div>
  </div>
</app-slide-in-right-modal>


<!-- <div *ngIf="isBankModalOpen">
  <app-create-account-bank (action)="isBankModalOpen = false;"></app-create-account-bank>
</div> -->