import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet, RouterLink, RouterLinkActive, Router } from '@angular/router';
import { CreateAccountLayoutComponent } from '@app/create-account/create-account.layout.component';
import { ButtonDirective } from '@app/shared/directives/button.directive';
import { InputLabelComponent } from '@app/shared/ui/input-label/input-label.component';
import { InputComponent } from '@app/shared/ui/input/input.component';
import { OnboardingHeadingComponent } from '@app/shared/ui/onboarding-heading/onboarding-heading.component';
import { ProgressStepsComponent } from '@app/shared/ui/progress-steps/progress-steps.component';
import { BaseSelectDirective } from '@app/shared/directives/base-select.directive';
import { corporateRoutesConfig } from '@create-account/corporate/corporate-routes.config';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CURRENCY } from '@app/core/constants/app.constant';
import { Observable, Subscription } from 'rxjs';
import { CommonService } from '@app/core/services/common.service';
import { KycService } from '@app/core/services/client/kyc.service';
import { NotificationFacade } from '@app/core/facades/notification.facade';
import { Store } from '@ngxs/store';
import { LoadingService } from '@app/core/services/loading.service';
import { AuthSelectors } from '@app/auth/store/auth.selectors';
import { SlideInRightModalComponent } from '@app/shared/ui/slide-in-right-modal/slide-in-right-modal.component';
import { CreateAccountDirectorDetailsComponent } from '../create-account-director-details/create-account-director-details.component';

@Component({
  selector: 'app-create-account-bank',
  standalone: true,
  imports: [
    CommonModule,
    SlideInRightModalComponent,
    OnboardingHeadingComponent,
    ProgressStepsComponent,
    InputLabelComponent,
    InputComponent,
    ButtonDirective,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    BaseSelectDirective,
    ReactiveFormsModule,
    FormsModule,
    CreateAccountDirectorDetailsComponent
  ],
  templateUrl: './create-account-bank.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateAccountBankComponent {
  CORPORATE_ROUTES = corporateRoutesConfig;

  @Output() action = new EventEmitter<void>();

  fb = inject(FormBuilder);
  commonService = inject(CommonService);
  kycService = inject(KycService);
  router = inject(Router);
  change = inject(ChangeDetectorRef);
  toast = inject(NotificationFacade);
  store = inject(Store);
  private loadingService = inject(LoadingService);


  subs: Subscription[] = [];

  currencyList = CURRENCY;
  bankSortCode!: string;
  banks: { bankName: string; bankCode: string }[] = [];
  isLoading$!: Observable<boolean>;
  isFetchingBankList$!: Observable<boolean>;
  isFetchingAcctNo$!: Observable<boolean>;
  accountId!: string;
  isDirectorDetailsModalOpen: boolean = false;

  bankDetailsForm = this.fb.nonNullable.group({
    bank_account_name: ['', Validators.required],
    bank_account_number: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
    bank_code: ['', Validators.required],
    bank_branch: ['', Validators.required],
    bank_sort_code: ['', Validators.required],
    account_id: [''],
    currency: ['', Validators.required],
  });

  constructor() {
    this.isLoading$ = this.loadingService.getLoadingObservable(
      'add-bank-details'
    );
    this.isFetchingBankList$ =
      this.loadingService.getLoadingObservable('get-bank-list');
    this.isFetchingAcctNo$ = this.loadingService.getLoadingObservable(
      'validate-bank-details'
    );
  }

  ngOnInit() {
    this.accountId = this.store.selectSnapshot(AuthSelectors.getActiveAccountID)!;


    if (this.accountId) {
      this.bankDetailsForm.patchValue({
        account_id: this.accountId,
      });
      this.getListOfBanks();
    }
    this.formChangesWatcher();
  }

  getListOfBanks() {
    this.loadingService.setLoading('get-bank-list', true);
    const sub = this.commonService.getBankList().subscribe({
      next: (res: any) => {
        this.loadingService.setLoading('get-bank-list', false);
        this.banks = res.data;
        this.change.detectChanges();
      },
      error: () => {
        this.loadingService.setLoading('get-bank-list', false);
        this.toast.info('Unable to fetch bank list.\nKindly refresh');

        this.change.detectChanges();
      },
    });
    this.subs.push(sub);
  }

  addBankDetails(): void {

    this.loadingService.setLoading('add-bank-details', true);
    this.kycService
      .addBankDetails(this.bankDetailsForm.getRawValue())
      .subscribe({
        next: (response) => {
          this.loadingService.setLoading('add-bank-details', false);
          this.change.detectChanges();
          this.toast.success(response.message);
          this.router.navigateByUrl('/dashboard/corporate/director')
          // this.isDirectorDetailsModalOpen = true;
        },
        error: (resp) => {
          this.loadingService.setLoading('add-bank-details', false);
          this.change.detectChanges();
          this.toast.error('Failed to add bank details');
        },
      });
  }

  validateAccount() {
    this.loadingService.setLoading('validate-bank-details', true);
    const account =
      this.bankDetailsForm.get('bank_account_number')?.value || '';
    const code = this.bankDetailsForm.get('bank_code')?.value || '';
    if (account?.length === 10 && code) {
      const sub = this.commonService
        .validateBankAccountNo(account, code)
        .subscribe({
          next: (res: any) => {
            this.loadingService.setLoading('validate-bank-details', false);
            this.bankDetailsForm
              .get('bank_account_name')
              ?.setValue(res.data?.accountName);
            this.change.detectChanges();
          },
          error: () => {
            this.loadingService.setLoading('validate-bank-details', false);
            this.toast.info('Unable validate account number.\nTry again');
            this.change.detectChanges();
          },
        });
      this.subs.push(sub);
    }
  }

  formChangesWatcher() {
    const sub1 = this.bankDetailsForm
      .get('bank_code')
      ?.valueChanges.subscribe((val) => {
        if (val && this.bankDetailsForm.get('bank_account_number')?.value) {
          this.validateAccount();
        }
      });

    const sub2 = this.bankDetailsForm
      .get('bank_account_number')
      ?.valueChanges.subscribe((val) => {
        if (val && this.bankDetailsForm.get('bank_code')?.value) {
          this.validateAccount();
        }
      });

    sub1 && this.subs.push(sub1);
    sub2 && this.subs.push(sub2);
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
  closeModal(): void {
    history.back()
  }
  skip(): void {
    this.router.navigateByUrl('/dashboard/corporate/director')
  }
}
