import { LoadingService } from '@app/core/services/loading.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateAccountLayoutComponent } from '@app/create-account/create-account.layout.component';
import { OnboardingHeadingComponent } from '@app/shared/ui/onboarding-heading/onboarding-heading.component';
import { ProgressStepsComponent } from '@app/shared/ui/progress-steps/progress-steps.component';
import { ButtonDirective } from '@app/shared/directives/button.directive';
import { InputLabelComponent } from '@app/shared/ui/input-label/input-label.component';
import { InputComponent } from '@app/shared/ui/input/input.component';
import { RouterOutlet, RouterLink, RouterLinkActive, Router } from '@angular/router';
import { corporateRoutesConfig } from '@create-account/corporate/corporate-routes.config';
import {
  ReactiveFormsModule,
  FormsModule,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { AuthSelectors } from '@app/auth/store/auth.selectors';
import { Observable, Subscription } from 'rxjs';
import { NotificationFacade } from '@app/core/facades/notification.facade';
import { Store } from '@ngxs/store';
import { KycService } from '@app/core/services/client/kyc.service';
import { SlideInRightModalComponent } from '@app/shared/ui/slide-in-right-modal/slide-in-right-modal.component';
import { CreateAccountConfirmDirectorComponent } from '@app/create-account/corporate/pages/create-account-confirm-director/create-account-confirm-director.component';

@Component({
  selector: 'app-create-account-director-details',
  standalone: true,
  imports: [
    CommonModule,
    SlideInRightModalComponent,
    OnboardingHeadingComponent,
    ProgressStepsComponent,
    ButtonDirective,
    InputLabelComponent,
    InputComponent,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    ReactiveFormsModule,
    FormsModule,
    CreateAccountConfirmDirectorComponent,
  ],
  templateUrl: './create-account-director-details.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateAccountDirectorDetailsComponent {
  CORPORATE_ROUTES = corporateRoutesConfig;
  @Output() action = new EventEmitter<void>();

  fb = inject(FormBuilder);
  loadingService = inject(LoadingService);
  change = inject(ChangeDetectorRef);
  toast = inject(NotificationFacade);
  store = inject(Store);
  kycService = inject(KycService);
  router = inject(Router);

  subs: Subscription[] = [];
  isLoading$!: Observable<boolean>;
  accountId!: string;
  isConfirmDirectoryModalOpen: boolean = false;

  directorDetailsForm = this.fb.nonNullable.group({
    first_name: ['', Validators.required],
    last_name: ['', Validators.required],
    email: ['', Validators.required],
    phone_number: ['', Validators.required],
    bvn: ['', Validators.required],
    account_id: [''],
  });


  constructor() {
    this.isLoading$ = this.loadingService.getLoadingObservable(
      'add-corporate-director-kyc'
    );
  }

  ngOnInit(): void {
    this.accountId = this.store.selectSnapshot(AuthSelectors.getActiveAccountID)!;
    if (this.accountId) {
      this.directorDetailsForm.patchValue({
        account_id: this.accountId,
      });
    }
  }

  addDirectorDetails(): void {
    this.loadingService.setLoading('add-corporate-director-kyc', true);

    const sub = this.kycService
      .directorDetails(this.directorDetailsForm.getRawValue())
      .subscribe({
        next: (response) => {
          this.loadingService.setLoading('add-corporate-director-kyc', false);
          this.change.detectChanges();

          this.toast.success(response.message);
          // this.isConfirmDirectoryModalOpen = true;
          this.router.navigateByUrl('/dashboard/corporate/confirm-director')
        },
        error: (resp) => {
          this.loadingService.setLoading('add-corporate-director-kyc', false);
          this.change.detectChanges();
          this.toast.error('Failed to add director details');
        },
      });

    this.subs.push(sub);
  }
  closeModal(): void {
    history.back()
    // this.action.emit();
  }
  skip(): void {
    this.router.navigateByUrl('/dashboard/corporate/confirm-director')
    // this.isConfirmDirectoryModalOpen = true
  }
  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
