import { Component, inject, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  Router,
} from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { CreateAccountService } from '@app/core/services/create-account.service';
import { NotificationFacade } from '@app/core/facades/notification.facade';
import { Store } from '@ngxs/store';
import { AuthSelectors } from '@app/auth/store/auth.selectors';
import { AuthActions } from '@app/auth/store/auth.actions';
import { SlideInRightModalComponent } from '@app/shared/ui/slide-in-right-modal/slide-in-right-modal.component';
import { PersonalComponent } from '../individual/personal/personal.component';
import { CreateAccountCompanyComponent } from '../corperate/create-account-company/create-account-company.component';

@Component({
  selector: 'app-account-type',
  standalone: true,
  imports: [
    CommonModule,
    SlideInRightModalComponent,
    CreateAccountCompanyComponent,
    PersonalComponent
  ],
  templateUrl: './account-type.component.html',
  styleUrls: ['./account-type.component.scss'],
})
export class AccountTypeComponent {
  @Output() action = new EventEmitter<void>();

  router = inject(Router);
  toast = inject(NotificationFacade);
  store = inject(Store);
  private createAcctService = inject(CreateAccountService);


  profileID = this.store.selectSnapshot(AuthSelectors.getProfileID);
  isCreatingAccount$!: Observable<boolean>;
  isIndividualPersonalOpen: boolean = false;
  isCoperateCompanyDetailsOpen: boolean = false;
  subs: Subscription[] = [];

  constructor() { }

  createIndividualAccount() {

    const sub = this.createAcctService
      .createAccount({
        account_type: 'individual',
        profile_id: this.profileID,
      })
      .subscribe({
        next: (resp: any) => {
          this.store.dispatch(new AuthActions.SetAccountID(resp.data.id));
          this.router.navigateByUrl('/dashboard/individual/personal')
        },
        error: () => {
          this.toast.error('Failed to create individual account');
        },
      });
    this.subs.push(sub);
  }

  createCorporateAccount() {

    const sub = this.createAcctService
      .createAccount({
        account_type: 'corporate',
        profile_id: this.profileID,
      })
      .subscribe({
        next: (resp: any) => {
          this.store.dispatch(new AuthActions.SetAccountID(resp.data.id));
          this.router.navigateByUrl('/dashboard/corporate/details')

        },
        error: () => {
          this.toast.error('Failed to create corporate account');
        },
      });
    this.subs.push(sub);
  }
  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  closeModal(): void {
    history.back()
  }

}
