<app-slide-in-right-modal
  [slideOpen]="true"
  (action)="closeModal()"
>
  <div class="flex flex-col gap-[1.88rem] xl:gap-5">
    <div class="flex items-center justify-center mb-5">
      <span class="text-xl font-bold text-mango-grey text-center flex-1">Means of Identification</span>
      <a class="font-extrabold text-mango text-sm cursor-pointer" (click)="skip()">
        Skip
      </a>
    </div>

    
      <div class="page__container flex flex-col gap-5 xl:gap-y-[2.81rem] col-start-2 col-end-4">
        <div class="grid grid-cols-[1fr_2fr_1fr] mb-[1.19rem] xl:my-5">
          <app-progress-steps class="col-start-2" [totalSteps]="6" [currentStep]="2" iconImageSrc="/assets/images/svgs/money-card.svg"></app-progress-steps>
        </div>

        <div class="form__container flex flex-col gap-5">
          <p class="text-sm text-mango-gray74 cta__text">Pick which ID you would like to scan</p>

          <form class="flex flex-col gap-5" [formGroup]="identificationForm">
            
            <div>
              <app-input-label labelFor="idType" labelClass="label--bottom-margin">Nationality</app-input-label>
              <select name="idType" required base-select formControlName="nationality">
                <option value="" disabled selected>Select Nationality</option>
                <option *ngFor="let country of countries" [value]="country">{{ country }}</option>
              </select>
            </div>

            <div>
              <app-input-label labelFor="idType" labelClass="label--bottom-margin">ID Type</app-input-label>
              <select name="idType" required base-select formControlName="type">
                <option value="" disabled selected>Select ID type</option>
                <option *ngFor="let idType of meansOfIdTypes" [value]="idType">{{ idType }}</option>
              </select>
            </div>

            <div>
              <app-input-label labelFor="id-number" labelClass="label--bottom-margin">ID Number</app-input-label>
              <app-input placeholder="Enter ID Number" inputClass="input--padding" formControlName="number"></app-input>
            </div>
          </form>
          <!-- <div
            class="upload-instructions__container bg-mango-floralWhite flex flex-col py-[1.19rem] px-5 gap-[0.62rem]"
          >
            <img
              src="/assets/images/svgs/info-circle--yellow.svg"
              class="w-[1.5625rem] h-[1.5625rem]"
              alt=""
            />
            <p class="text-sm text-mango-grey leading-[1.3125rem]">
              To capture a clear document: place it on a well-lit surface, align
              your camera, make sure it's focused, and check for clarity.
            </p>
          </div> -->
          <div class="inline-flex items-center gap-[0.9rem] rounded-xl bg-mango-floralWhite px-[0.6rem] py-[0.9rem]">
            <img src="/assets/images/svgs/info-circle--yellow.svg" alt="" class="h-[1.5625rem] w-[1.5625rem] flex-1" />
            <p class="text-sm leading-[1.3125rem] text-mango-grey">
              Document should not be larger than 2MB. File types accepted include PDF, JPEG, and PNG.
            </p>
          </div>

          <app-input-file
            [inputId]="documentInfo.id"
            [inputLabel]="documentInfo.inputLabel"
            (uploadedFileEvent)="getUploadedFile($event, documentInfo.key)"
            [maxFileSize]="2000"
            [acceptedFileTypes]="['image/png', 'image/jpeg', 'application/pdf']"
          >
          </app-input-file>

          
            <div [ngClass]="{ 'cursor-not-allowed': isLoading }" >
              <button
                mango-button
                (click)="submitForm()"
                [disabled]="isLoading"
               
              >
                <span *ngIf="isLoading">Submitting...</span>
                <span *ngIf="!isLoading">Continue</span>
              </button>
            
          </div>
        </div>
      </div>
  </div>
</app-slide-in-right-modal>


<!-- <div *ngIf="isKinModalOpen">
  <app-create-account-kin></app-create-account-kin>
</div> -->